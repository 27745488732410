<template>
  <div class="gestionInspecteur">

    <h3 v-if="flagEditAffaire == false && flagInfoAffaire == false"><i class="fa-regular fa-file-lines"></i>LISTE DES
      OFFRES COMMERCIAUX</h3>
    <div class="rechercher-table" v-if="flagEditAffaire == false && flagInfoAffaire == false">

      <div class="rechercher">

        <!-- Filter by Raison Social -->
        <select v-model="rechercher" @input="setRechercher($event)">
          <option v-for="item in clients" :key="item.raisonSocial" :value="item._id">{{ item.raisonSocial }}</option>
        </select>

        <!-- Filter by Year -->
        <select v-model="year" @input="setRechercherAnnee($event)">
          <option v-for="item in maxYears" :key="item" :value="item">{{ item }}</option>
        </select>

        <input type="button" value="Importer" @click="handelGenerateListeOffre">


      </div>


      <div class="gestionaffaire" v-if="flagUplodeBonCammnade == true">
        <h4>Information sur Le Bon Commande du Client</h4>
        <label class="entre-prestations">Remplir les libellé de prestations de client</label>
        <input v-for="(item, index) in libelles" :key="index" type="text" v-model="libelles[index]" placeholder=" ">

        <div>
          <input style="color :white; border: 0px;" class="ajouter" type="submit" value="Ajouter"
            @click="ajouterLibelle">
          <input style="color :white; border: 0px;" class="supprimer" type="submit" value="Supprimer"
            @click="supprimerLibelle">
        </div>

      </div>

      <div class="gestionaffaire" v-if="flagUplodeBonCammnade == true">
        <input type="text" v-model="numeroBonCammnade" placeholder="Remplir le numéro de Bon Commande de client">
        <input type="text" v-model="prixBonCammnade" placeholder="Remplir le prix générale de Bon Commande de client">
        <input type="date" v-model="dateBonCammnade">
        <input type="file" multiple="multiple" class="form-control" placeholder="Fichier PDF" ref="file"
          @change="previewFile">
        <input style="color :white; border: 0px;" class="bonCammande" type="submit"
          v-show="flagPreviewUplodeBonCammnade == true" value="Valider" @click="enregitreBonCommande">
        <input style="color :white; border: 0px;" class="annuler" type="submit" value="Annuler"
          @click="annulerBonCommande">
      </div>

      <table id="inspecteurs">
        <tr>
          <th> </th>
          <th>Année</th>
          <th>Numéro d'offre</th>
          <th>Réf Client</th>
          <th>Raison sociale </th>
          <th>Mission</th>
          <th>Date</th>
          <th>Actions</th>
          <th colspan="7">Status</th>
          <th>OP</th>
          <th>OCT</th>
          <!-- <th>SC</th> -->

        </tr>
        <tr v-for="(affaire, i) in affaires" :key="affaire._id">
          <td>
            <input type="checkbox" :value="affaire._id" v-model="checkedAffaires" style="width: 15px;">
          </td>
          <td>
            <p> {{ new Date(affaire.date).getFullYear() }}</p>
          </td>
          <td>{{ affaire.numeroAffaire }}</td>
          <td>
            <p v-for="(client, index) in clients" :key="index">{{
              affaire.numeroAffaire.substr(affaire.numeroAffaire.lastIndexOf("|") + 1, affaire.numeroAffaire.length) ===
                client.raisonSocial ? client.refClient : '' }}</p>
          </td>
          <td>
            <p>{{ affaire.client }}</p>
          </td>
          <td v-if="affaire.numeroAffaire">
            <p v-for="(mission, index) in missions" :key="index"> {{ mission.numeroAffaire == affaire.numeroAffaire ?
              mission.codeMission : '' }}</p>
          </td>
          <td>
            <p> {{ new Date(affaire.date).toLocaleDateString() }}</p>
          </td>
          <td>
            <a @click="deleteAffaire(i)" title="Supprimé"><i class="fa-solid fa-trash"></i></a>
            <a @click="informationAffaire(i)" title="Plus Info"><i class="fa-solid fa-magnifying-glass"></i></a>
            <a @click="gestionRapport(affaire._id)" title="Gestion des rapports"><i class="fa-solid fa-folder"></i></a>
          </td>

          <td v-if="affaire.nameFileAffaire != 'false'" title="Télécharger Devis"
            style=" margin : 0; padding: 0; padding-bottom: 5px; padding-left: 5px;"
            @click="displayDEVIS(affaire.nameFileAffaire)">
            <p style="font-size:20px; color:green; font-weight: bold;font-weight: bold;">P</p><i
              class="fa-solid fa-download" style="font-size:20px; color:white;"></i>
          </td>
          <td v-if="affaire.nameFileAffaire == 'false'"
            style=" margin : 0; padding: 0; padding-bottom: 5px; padding-left: 5px;">
            <p style="color: red; font-size:20px;">P</p><i class="fa-solid fa-download"
              style="font-size:20px; color:white;"></i>
          </td>

          <td v-if="affaire.bonCommande != 'false'" title="Télécharger Bon de commande client"
            style=" margin : 0; padding: 0; padding-bottom: 5px; padding-left: 5px;"
            @click="displayBC(affaire.bonCommande)">
            <p v-if="affaire.bonCommande != 'false'" style="font-size:20px; color:green; font-weight: bold;">AP</p>
            <i v-if="affaire.bonCommande != 'false'" style="font-size:20px; color:green;"
              class="fa-solid fa-download"></i>
          </td>

          <td v-if="affaire.bonCommande == 'false'" title="Saisir Bon de commande client"
            @click="bonCommande(affaire._id, i)"
            style=" margin : 0; padding: 0; padding-bottom: 5px; padding-left: 5px;">
            <p v-if="affaire.bonCommande == 'false'" style="font-size:20px; color:red; font-weight: bold;">AP</p>
            <i v-if="affaire.bonCommande == 'false'" style="font-size:20px; color:red;"
              class="fa-solid fa-forward-step"></i>
          </td>

          <td v-if="affaire.renseignerIntervention != 'false'"
            style=" margin : 0; padding: 0; padding-bottom: 5px; padding-left: 5px;"
            @click="generateIntervention(affaire._id)">
            <p style="font-size:20px; color:green; font-weight: bold;">EP</p><i class="fa-solid fa-download"
              style="font-size:20px; color:green;"></i>
          </td>
          <td v-if="affaire.renseignerIntervention == 'false'"
            style=" margin : 0; padding: 0; padding-bottom: 5px; padding-left: 5px;">
            <p style="font-size:20px; color:red; font-weight: bold;">EP</p><i class="fa-solid fa-forward-step"
              style="font-size:20px; color:white;"></i>
          </td>

          <td v-if="affaire.importRapport != 'false'"
            style=" margin : 0; padding: 0; padding-bottom: 5px; padding-left: 5px;">
            <p style="font-size:20px; color:green; font-weight: bold;">RE</p><i class="fa-solid fa-download"
              style="font-size:20px; color:white;"></i>
          </td>
          <td v-if="affaire.importRapport == 'false'"
            style=" margin : 0; padding: 0; padding-bottom: 5px; padding-left: 5px;">
            <p style="font-size:20px; color:red;  font-weight: bold;">RE</p><i class="fa-solid fa-forward-step"
              style="font-size:20px; color:white;"></i>
          </td>

          <td v-if="affaire.be != 'false'" style=" margin : 0; padding: 0; padding-bottom: 5px; padding-left: 5px;"
            @click="generateBD(affaire._id)">
            <p style="font-size:20px; color:green; font-weight: bold;">BE</p><i class="fa-solid fa-download"
              style="font-size:20px; color:green;"></i>
          </td>
          <td v-if="affaire.be == 'false'" style=" margin : 0; padding: 0; padding-bottom: 5px; padding-left: 5px;">
            <p style="font-size:20px; color:red; font-weight: bold;">BE</p><i class="fa-solid fa-forward-step"
              style="font-size:20px; color:white;"></i>
          </td>

          <td v-if="affaire.facture != 'false'" style=" margin : 0; padding: 0; padding-bottom: 5px; padding-left: 5px;"
            @click="generateFacture(affaire._id)">
            <p style="font-size:20px; color:green; font-weight: bold;">FA</p><i class="fa-solid fa-download"
              style="font-size:20px; color:green;"></i>
          </td>
          <td v-if="affaire.facture == 'false'"
            style=" margin : 0; padding: 0; padding-bottom: 5px; padding-left: 5px;">
            <p style="font-size:20px; color:red; font-weight: bold;">FA</p><i class="fa-solid fa-forward-step"
              style="font-size:20px; color:white;"></i>
          </td>

          <td v-show="affaire.emise != 'false'" style=" margin : 0; padding: 0; padding-bottom: 5px; padding-left: 5px;"
            @click="showEmise(affaire.emise)">
            <p style="font-size:20px; color:green; font-weight: bold;">EN</p><i class="fa-solid fa-download"
              style="font-size:20px; color:green;"></i>
          </td>
          <td v-show="affaire.emise == 'false'"
            style=" margin : 0; padding: 0; padding-bottom: 5px; padding-left: 5px;">
            <p style="font-size:20px; color:red; font-weight: bold;">EN</p><i class="fa-solid fa-forward-step"
              style="font-size:20px; color:white;"></i>
          </td>

          <!-- for download offre prix -->
          <td title="Génère l'offre de prix" style="margin : 0; padding: 0; padding-bottom: 5px; padding-left: 5px;"
            @click="generateOffrePrix(affaire.numeroAffaire)"><i class="fa-solid fa-download"
              style="font-size:20px; color:green;"></i></td>
          <!-- for download offre commercial -->
          <td title="Génère l'offre Commerciale Technique"
            style="margin : 0; padding: 0; padding-bottom: 5px; padding-left: 5px;"
            @click="generateOffreCommerciale(affaire.numeroAffaire)"><i class="fa-solid fa-download"
              style="font-size:20px; color:green;"></i></td>

        </tr>
      </table>

      <div class="deleteAll" v-show="checkedAffaires.length > 1">
        <input type="submit" value="Supprimer tout" @click="deleteAffaires()">
      </div>


    </div>

    <ul class="pagination" v-if="flagEditAffaire == false && flagInfoAffaire == false">
      <button @click="selectPageLeft()"><i class="fa-solid fa-backward"></i></button>
      <li v-for="n in numberPage" :key="n" @click="selectPage(n)">
        <p v-show="numberPageSelect[n - 1] == false">{{ n }}</p>
        <p v-show="numberPageSelect[n - 1] == true" style="background-color: #0300db; color: white;">{{ n }}</p>
      </li>
      <button @click="selectPageRight()"><i class="fa-solid fa-forward"></i></button>
    </ul>

    <!--  Start Edit Client   -->
    <EditAffaire :infoAffaire="infoAffaire" v-if="flagEditAffaire == true" />
    <!--  End Edit Affaire   -->

    <!--  Start Info Affaire   -->
    <InfoAffaire :infoAffaire="infoAffaire" v-if="flagInfoAffaire == true" />
    <!--  End Info Affaire   -->

    <!--  Start Info Delete Model   -->
    <DeleteModel v-if="flagModelDelete == true" :text="textDeleteModel" @closeDeleteModel="closeDeleteModel()"
      @validerDeleteModel="validerDeleteModel()" />
    <!--  End Info Delete Model   -->

    <!--  Start Info Evaluateur   -->
    <EvaluateurModal v-if="flagEvaluateurModal == true" :evaluateurs="interlocuteurs"
      @closeEvaluateurModal="closeEvaluateurModal()" @validerEvaluateurModal="validerEvaluateurModal" />
    <!--  End Info Evaluateur   -->

    <!--  handel Generate liste Offre   -->
    <GenerateListeOffreModal v-if="flagGenerateListeOffreModal == true"
      @validerGenerateListeOffreModal="validerGenerateListeOffreModal"
      @closeGenerateListeOffreModal="closeGenerateListeOffreModal" />
    <!--  End Info Evaluateur   -->

    <!-- Start Erorrs and Landing -->
    <ModalLanding v-if="flagModalLanding" />
    <ModalErrors v-if="flagModalErrors" :errors="errors" />
    <!-- Fin Erorrs and Landing -->

    <!-- Start Erorrs and Landing -->
    <GestionRapport v-if="flagGestionRapport" :affaireId="affaireId"
      @closeGestionRapportModel="closeGestionRapportModel" @valideGestionRapportModel="valideGestionRapportModel" />
    <!-- Fin Erorrs and Landing -->

  </div>

</template>

<script>
import Service from "../../../../Service";
import ModalLanding from "../../../../views/components/ModalLanding.vue";
import ModalErrors from "../../../../views/components/ModalErrors.vue"; import EditAffaire from "./EditAffaire.vue";
import InfoAffaire from "./InfoAffaire.vue";
import DeleteModel from "../../../../views/components/DeleteModel.vue";
import GenerateListeOffreModal from "../../../../views/components/GenerateListeOffreModal.vue";
import EvaluateurModal from "../../../../views/components/EvaluateurModal.vue";
import GestionRapport from "@/components/Admin/content/Affaire/ComponentGestionRapport/GestionRapport.vue";

export default {
  name: "gestionInterlocuteur",
  components: {
    ModalLanding,
    ModalErrors,
    DeleteModel,
    EditAffaire,
    InfoAffaire,
    EvaluateurModal,
    GenerateListeOffreModal,
    GestionRapport
  },
  data() {
    return {
      flagGestionRapport: false,
      flagGenerateListeOffreModal: false,
      libelles: [],
      flagEvaluateurModal: false,
      tableNumeroAffaire: [],
      flagModelDelete: false,
      textDeleteModel: null,
      savedIndexDeleteModel: null,
      pageIndex: 0,
      itemsPerPage: 10,
      numberPage: null,
      numberPageSelect: [],
      flagModalLanding: false,
      flagModalErrors: false,
      numeroBonCammnade: null,
      prixBonCammnade: null,
      dateBonCammnade: null,
      currentIndexBonCammande: null,
      flagPreviewUplodeBonCammnade: false,
      file: null,
      affaireId: null,
      flagUplodeBonCammnade: false,
      rechercher: null,
      preAffaires: [],
      affaires: [],
      missions: [],
      clients: [],
      checkedClients: [],
      msg: null,
      flagEditClient: false,
      flagInfoClient: false,
      infoClient: [],
      echec: null,
      checkedAffaires: [],
      flagEditAffaire: false,
      flagInfoAffaire: false,
      infoAffaire: [],
      interlocuteurs: [],
      maxYears: [],
      year: null
    };
  },
  methods: {

    closeGestionRapportModel() {
      this.flagGestionRapport = false
    },

    valideGestionRapportModel() {
      this.flagGestionRapport = false
    },

    // pour gestion rapport
    gestionRapport(affaireId) {
      this.flagGestionRapport = true;
      this.affaireId = affaireId;
    },

    setRechercherAnnee(event) {
      this.year = event.target.value;
      this.handelAffairesCurrent(this.year);
    },

    setRechercher(event) {
      this.rechercher = event.target.value;
      this.handelAffairesCurrentById(this.rechercher);
    },



    downloadFileExcel(response) {

      var newBlob = new Blob([response.data], {
        type: "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
      });

      if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveOrOpenBlob(newBlob);
        return;
      }

      const data = window.URL.createObjectURL(newBlob);
      var link = document.createElement("a");
      link.href = data;
      link.download = "resume.xlsx";
      link.click();
      setTimeout(function () {
        window.URL.revokeObjectURL(data);
      }, 100);

    },

    generateOffrePrix(numeroAffaire) {

      Service.generateOffrePrix(numeroAffaire)
        .then((response) => {

          if (response.data.msg == true) {
            Service.downloadOffrePrix(numeroAffaire)
              .then((response) => {
                this.downloadFile(response);
              })
              .catch((error) => {
                console.log(error.message);
              });
          }

        })
        .catch((error) => {
          console.log(error.message);
        });
    },

    generateOffreCommerciale(numeroAffaire) {
      Service.generateOffreCommerciale(numeroAffaire)
        .then((response) => {
          if (response.data.msg == true) {
            Service.downloadOffreCommerciale(numeroAffaire)
              .then((response) => {
                this.downloadFile(response);
              })
              .catch((error) => {
                console.log(error.message);
              });
          }
        })
        .catch((error) => {
          console.log(error.message);
        });
    },

    validerEvaluateurModal(emails) {

      Service.statisfactionClient(this.numeroAffaire, this.clientId, emails)
        .then((result) => {
          if (result) {
            this.flagEvaluateurModal = false;
            //get index for change status statisfaction red to orange
            const index = this.affaires.findIndex(object => {
              return object._id === this.affaireId;
            });
            // change status
            this.affaires[index].satisfaction = "attente";

          }
        })
        .catch((error) => {
          console.log(error.message);
        });

    },

    closeEvaluateurModal() {
      this.flagEvaluateurModal = false;
    },


    selectPageLeft() {

      // get first page
      for (let i = 0; i < this.numberPage; i++) {
        this.numberPageSelect[i] = false;
      }

      this.numberPageSelect[0] = true;
      this.affaires = [];

      for (let i = 0 * this.itemsPerPage; i < (0 * this.itemsPerPage) + this.itemsPerPage; i++) {
        if (this.preAffaires[i] != undefined) {
          this.affaires.push(this.preAffaires[i]);
        }
      }

    },

    selectPageRight() {

      // get first page
      for (let i = 0; i < this.numberPage; i++) {
        this.numberPageSelect[i] = false;
      }

      const indice = this.numberPage - 1;
      this.numberPageSelect[indice] = true;
      this.affaires = [];

      for (let i = indice * this.itemsPerPage; i < (indice * this.itemsPerPage) + this.itemsPerPage; i++) {
        if (this.preAffaires[i] != undefined) {
          this.affaires.push(this.preAffaires[i]);
        }
      }

    },

    selectPage(value) {

      // vide liste affaires
      this.affaires = [];

      // set index specfique
      const select = value - 1;

      // change current select for change color
      for (let i = 0; i < this.numberPage; i++) {
        this.numberPageSelect[i] = false;
      }

      // select page and set value
      this.numberPageSelect[select] = true;

      // set affaires by index select
      for (let i = select * this.itemsPerPage; i < (select * this.itemsPerPage) + this.itemsPerPage; i++) {
        if (this.preAffaires[i] != undefined) {
          this.affaires.push(this.preAffaires[i]);
        }
      }

    },

    // edit Affaire and info Affaire
    editAffaire(i) {
      this.infoAffaire.push(this.affaires[i])
      this.flagEditAffaire = true;
      this.flagInfoAffaire = false;
    },

    // edit one client
    informationAffaire(i) {
      this.infoAffaire.push(this.affaires[i])
      this.flagEditAffaire = false;
      this.flagInfoAffaire = true;
    },

    closeDeleteModel() {
      this.flagModelDelete = false;
    },

    validerDeleteModel() {

      this.flagModelDelete = false;

      if (this.savedIndexDeleteModel == null && this.checkedAffaires.length > 0) {

        for (let i = 0; i < this.checkedAffaires.length; i++) {
          this.affaires.splice(this.checkedAffaires[i], 1);
        }

        Service.deleteAffaire(this.checkedAffaires)
          .then((result) => {
            this.msg = result.data.msg;
          })
          .catch((error) => {
            this.msg = error.message;
            console.error(`HTTP error: ${error.name} => ${error.message}`);
            throw "fail request at: GET /refreshtime";
          });

      }

      if (this.savedIndexDeleteModel != null && this.checkedAffaires.length == 0) {

        const i = this.savedIndexDeleteModel;
        const affaireId = this.affaires[i]._id;
        this.affaires.splice(i, 1);
        Service.deleteAffaire(affaireId)
          .then((result) => {
            this.msg = result.data.msg;
          })
          .catch((error) => {
            this.msg = error.message;
            console.error(`HTTP error: ${error.name} => ${error.message}`);
            throw "fail request at: GET /refreshtime";
          });
      }

      this.savedIndexDeleteModel = null;
      this.checkedAffaires = [];
    },

    // delete Affaire
    deleteAffaire(i) {
      this.flagModelDelete = true;
      this.textDeleteModel = "Êtes-vous sûr de supprimer ?"
      this.savedIndexDeleteModel = i;
    },

    // delete more one affaire (affaires)
    deleteAffaires() {
      this.flagModelDelete = true;
      this.textDeleteModel = "Êtes-vous sûr de supprimer tout ?"
    },

    // annelle Modal Bon Commande
    annulerBonCommande() {
      this.flagUplodeBonCammnade = false;
    },

    // download file extention DOCX
    downloadFile(response) {
      var newBlob = new Blob([response.data], {
        type: "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
      });
      if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveOrOpenBlob(newBlob);
        return;
      }
      const data = window.URL.createObjectURL(newBlob);
      var link = document.createElement("a");
      link.href = data;
      link.download = "resume.docx";
      link.click();
      setTimeout(function () {
        window.URL.revokeObjectURL(data);
      }, 100);
    },

    // Generate Borderau D'envoi
    generateBD(affaireId) {
      Service.generateBD(affaireId)
        .then((res) => {
          if (res.data.msg == true) {
            Service.downloadBD()
              .then((response) => {
                this.downloadFile(response);
              })
              .catch((error) => {
                console.error(`HTTP error: ${error.name} => ${error.message}`);
                throw "fail request at: GET /refreshtime";
              });
          }
        })
        .catch((error) => {
          console.error(`HTTP error: ${error.name} => ${error.message}`);
        });
    },

    // genrate Facture
    generateFacture(affaireId) {
      Service.generateFacture(affaireId)
        .then((res) => {
          if (res.data.msg == true) {
            Service.downloadFacture()
              .then((response) => {
                this.downloadFile(response);
              })
              .catch((error) => {
                console.error(`HTTP error: ${error.name} => ${error.message}`);
                throw "fail request at: GET /refreshtime";
              });
          }
        })
        .catch((error) => {
          console.error(`HTTP error: ${error.name} => ${error.message}`);
        });
    },

    // Generate Intervention
    generateIntervention(affaireId) {
      Service.generateIntervention(affaireId)
        .then((res) => {
          if (res.data.msg == true) {
            Service.displayIntervention()
              .then((response) => {
                this.downloadFile(response);
              })
              .catch((error) => {
                console.error(`HTTP error: ${error.name} => ${error.message}`);
                throw "fail request at: GET /refreshtime";
              });
          }
        })
        .catch((error) => {
          console.error(`HTTP error: ${error.name} => ${error.message}`);
        });
    },

    // display Bon Cammande
    displayBC(filename) {
      Service.displayBC(filename)
        .then((data) => {
          console.log(data);
        })
        .catch((error) => {
          console.error(`HTTP error: ${error.name} => ${error.message}`);
          throw "fail request at: GET /refreshtime";
        });
    },


    // display DEVIS
    displayDEVIS(filename) {
      Service.displayDEVIS(filename)
        .then((data) => {
          console.log(data);
        })
        .catch((error) => {
          console.error(`HTTP error: ${error.name} => ${error.message}`);
          throw "fail request at: GET /refreshtime";
        });
    },

    //handel insert Intervention
    handelInsertIntervention(affaireId) {
      this.affaireId = affaireId
      this.flagInsertIntervention = true;
    },

    // Enregistre Bon Cammande
    enregitreBonCommande() {
      Service.enregitreBonCommande(this.file, this.affaireId, this.dateBonCammnade, this.numeroBonCammnade, this.libelles, this.prixBonCammnade)
        .then((response) => {

          this.flagUplodeBonCammnade = false;
          this.flagModalLanding = true;

          if (response) {
            setTimeout(() => {
              this.flagModalLanding = false;
              this.numeroBonCammnade = null;
              this.dateBonCammnade = null;
              this.prixBonCammnade = null;
              this.libelles = [];
              this.libelles.push("");
            }, 5000);
          }

          this.affaires[this.currentIndexBonCammande].bonCommande = response.result;
        })
        .catch((error) => {
          this.flagModalErrors = true;
          this.errors.push(`HTTP error: ${error.name} => ${error.message}`)
        });
    },


    // preciew file
    previewFile() {
      this.file = this.$refs.file.files[0];
      if (this.file) {
        this.flagPreviewUplodeBonCammnade = true;
      }
    },


    // create bon cammnde
    bonCommande(affaireId, index) {

      if (this.flagUplodeBonCammnade == true) {
        this.affaireId = null;
        this.flagUplodeBonCammnade = false;

      } else {
        this.currentIndexBonCammande = index;
        this.affaireId = affaireId;
        this.flagUplodeBonCammnade = true;
      }
    },

    // delete more one client (clients)
    deleteClients() {
      const idClients = new Array();
      for (let i = 0; i < this.checkedClients.length; i++) {
        idClients[i] = this.checkedClients[i];
        this.clients.splice(this.checkedClients[i], 1);

        Service.deleteClient(idClients[i])
          .then((result) => {
            if (result) {
              this.checkedAffaires = [];
            }
          })
          .catch((error) => {
            this.msg = error.message;
            console.error(`HTTP error: ${error.name} => ${error.message}`);
            throw "fail request at: GET /refreshtime";
          });
      }


    },

    // delete one client
    deleteClient(i) {
      const clientId = this.clients[i]._id;
      this.clients.splice(i, 1);
      Service.deleteClient(clientId)
        .then((result) => {
          // empty arry checkedAffaires
          if (result) {
            this.checkedAffaires = [];
          }
        })
        .catch((error) => {
          this.msg = error.message;
          console.error(`HTTP error: ${error.name} => ${error.message}`);
          throw "fail request at: GET /refreshtime";
        });
    },

    // edit one client
    editClient(i) {
      this.infoClient.push(this.clients[i])
      this.flagInfoClient = false;
      this.flagEditClient = true;
    },

    // edit one client
    informationClient(i) {
      this.infoClient.push(this.clients[i])
      this.flagEditClient = false;
      this.flagInfoClient = true;
    },

    ajouterLibelle() {
      this.libelles.push(null);
    },


    supprimerLibelle() {
      if (this.libelles.length > 1) {
        this.libelles.pop();
      }
    },

    handelGenerateListeOffre() {
      this.flagGenerateListeOffreModal = true;
    },

    closeGenerateListeOffreModal() {
      this.flagGenerateListeOffreModal = false;
    },

    validerGenerateListeOffreModal(e) {
      Service.generateListeOffre(e)
        .then((result) => {
          if (result.data.cas == true) {
            Service.downloadListeOffre()
              .then((response) => {
                this.downloadFileExcel(response);
                this.flagGenerateListeOffreModal = false;
              })
              .catch((error) => {
                console.log(error.message);
              });
          }
        })
        .catch((error) => {
          console.log(error.message);
        });
    },

    handelAffairesCurrentById(idRaisonSocial) {

      this.numberPage = null;
      this.numberPageSelect = [];
      this.preAffaires = [];
      this.affaires = [];
      this.missions = [];
      this.clients = [];

      // read all Affaires
      Service.readAllAffairesByRaisonSocial(idRaisonSocial)
        .then((result) => {

          for (let i = 0; i < result.data.result.length; i++) {
            this.affaires.push(result.data.result[i]);
          }

        })
        .catch((error) => {
          this.msg = error.message;
          console.error(`HTTP error: ${error.name} => ${error.message}`);
          throw "fail request at: GET /refreshtime";
        });

      // Read all missions
      Service.readAllMissions()
        .then((result) => {
          this.missions = result.data.missions;
        })
        .catch((error) => {

          this.msg = error.message;
          console.error(`HTTP error: ${error.name} => ${error.message}`);
          throw "fail request at: GET /refreshtime";
        });



      // Read all clients and check rapport exist or no
      Service.readClient()
        .then((result) => {
          this.clients = result.data.clients;
          // check rapports exist and valide
          Service.checkRapports()
            .then((result) => {
              var objectsFound = [];
              // search db atlas cloud
              for (let objectNumber in result.clients) {
                var nomSociete = result.clients[objectNumber].nomSociete;
                var idSociete = result.clients[objectNumber]._id;
                //search db local
                this.clients.forEach(element => {
                  if (element.raisonSocial == nomSociete) {
                    // serach rappport with consition (confirmation & clientId)
                    result.rapports.forEach(element => {
                      if (element.confirmation == 1 && element.clientId == idSociete) {
                        objectsFound.push(nomSociete);
                      }
                    })

                  }
                });
              }

            })
            .catch((error) => {
              console.log(error)
            });

        })
        .catch((error) => {
          this.msg = error.message;
          console.error(`HTTP error: ${error.name} => ${error.message}`);
          throw "fail request at: GET /refreshtime";
        });

    },

    handelAffairesCurrent(year) {

      this.numberPage = null;
      this.numberPageSelect = [];
      this.preAffaires = [];
      this.affaires = [];
      this.missions = [];
      this.clients = [];

      // read all Affaires
      Service.readAllAffaires(year)
        .then((result) => {

          for (let i = 0; i < result.data.result.length; i++) {
            this.preAffaires.push(result.data.result[i]);
          }

          if (this.preAffaires.length > 10) {

            this.numberPage = Math.ceil((this.preAffaires.length) / this.itemsPerPage);
            for (let i = 0; i < this.numberPage; i++) {
              this.numberPageSelect.push(false);
            }

            this.numberPageSelect[0] = true;

          } else {
            this.numberPage = 1;
            this.numberPageSelect[0] = true;
          }

          // numberPageSelect
          for (let i = this.pageIndex * this.itemsPerPage; i < (this.pageIndex * this.itemsPerPage) + this.itemsPerPage; i++) {
            if (this.preAffaires[i] != undefined) {
              this.affaires.push(this.preAffaires[i]);
            }

          }

        })
        .catch((error) => {
          this.msg = error.message;
          console.error(`HTTP error: ${error.name} => ${error.message}`);
          throw "fail request at: GET /refreshtime";
        });


      // Read all missions
      Service.readAllMissions()
        .then((result) => {
          this.missions = result.data.missions;
        })
        .catch((error) => {

          this.msg = error.message;
          console.error(`HTTP error: ${error.name} => ${error.message}`);
          throw "fail request at: GET /refreshtime";
        });



      // Read all clients and check rapport exist or no
      Service.readClient()
        .then((result) => {
          this.clients = result.data.clients;
          // check rapports exist and valide
          Service.checkRapports()
            .then((result) => {
              var objectsFound = [];
              // search db atlas cloud
              for (let objectNumber in result.clients) {
                var nomSociete = result.clients[objectNumber].nomSociete;
                var idSociete = result.clients[objectNumber]._id;
                //search db local
                this.clients.forEach(element => {
                  if (element.raisonSocial == nomSociete) {
                    // serach rappport with consition (confirmation & clientId)
                    result.rapports.forEach(element => {
                      if (element.confirmation == 1 && element.clientId == idSociete) {
                        objectsFound.push(nomSociete);
                      }
                    })

                  }
                });
              }

            })
            .catch((error) => {
              console.log(error)
            });

        })
        .catch((error) => {
          this.msg = error.message;
          console.error(`HTTP error: ${error.name} => ${error.message}`);
          throw "fail request at: GET /refreshtime";
        });

    },

    handelAffairesPrevent(year) {

      // read all Affaires
      Service.readAllAffaires(year)
        .then((result) => {

          for (let i = 0; i < result.data.result.length; i++) {
            this.preAffaires.push(result.data.result[i]);
          }

          if (this.preAffaires.length > 10) {

            this.numberPage = Math.ceil((this.preAffaires.length) / this.itemsPerPage);
            for (let i = 0; i < this.numberPage; i++) {
              this.numberPageSelect.push(false);
            }

            this.numberPageSelect[0] = true;

          } else {
            this.numberPage = 1;
            this.numberPageSelect[0] = true;
          }

          // numberPageSelect
          for (let i = this.pageIndex * this.itemsPerPage; i < (this.pageIndex * this.itemsPerPage) + this.itemsPerPage; i++) {
            if (this.preAffaires[i] != undefined) {
              this.affaires.push(this.preAffaires[i]);
            }
          }

        })
        .catch((error) => {
          this.msg = error.message;
          console.error(`HTTP error: ${error.name} => ${error.message}`);
          throw "fail request at: GET /refreshtime";
        });

      // Read all missions
      Service.readAllMissions()
        .then((result) => {
          this.missions = result.data.missions;
        })
        .catch((error) => {

          this.msg = error.message;
          console.error(`HTTP error: ${error.name} => ${error.message}`);
          throw "fail request at: GET /refreshtime";
        });

      // Read all clients and check rapport exist or no
      Service.readClient()
        .then((result) => {
          this.clients = result.data.clients;
          // check rapports exist and valide
          Service.checkRapports()
            .then((result) => {
              var objectsFound = [];
              // search db atlas cloud
              for (let objectNumber in result.clients) {
                var nomSociete = result.clients[objectNumber].nomSociete;
                var idSociete = result.clients[objectNumber]._id;
                //search db local
                this.clients.forEach(element => {
                  if (element.raisonSocial == nomSociete) {
                    // serach rappport with consition (confirmation & clientId)
                    result.rapports.forEach(element => {
                      if (element.confirmation == 1 && element.clientId == idSociete) {
                        objectsFound.push(nomSociete);
                      }
                    })

                  }
                });
              }

            })
            .catch((error) => {
              console.log(error)
            });

        })
        .catch((error) => {
          this.msg = error.message;
          console.error(`HTTP error: ${error.name} => ${error.message}`);
          throw "fail request at: GET /refreshtime";
        });
    }

  },

  computed: {

  },

  created() {

    // set date current and list dates 
    const date = new Date()
    this.year = date.getFullYear();
    this.numberPage = null;
    this.numberPageSelect = [];
    this.preAffaires = [];
    this.affaires = [];
    this.missions = [];
    this.clients = [];


    // set array years 2019 ----- Current year
    for (let j = date.getFullYear(); j >= 2019; j--) {
      this.maxYears.push(j);
    }

    // init libelle par default pour sisaire les prestations de client
    this.libelles.push("");

    // disabled Model delete
    this.flagModelDelete = false;

    //change status statitsfaction all affaires 
    Service.changeStatusStatisfactionAffaires()
      .then((response) => {
        console.log(response);
      })
      .catch((error) => {
        console.log(error)
      });

    // handel launch affaires
    this.handelAffairesPrevent(this.year);


  }

};
</script>

<style scoped>
.succes {
  background-color: green;
  color: white;
  padding: 10px;
  height: fit-content;
  width: 100%;
}

.echec {
  background-color: red;
  color: white;
  padding: 10px;
  height: fit-content;
  width: 100%;
}

.gestionInspecteur {
  width: 100%;
  height: 100%;
  padding: 3px;
  margin: 0px;
  position: relative;
}

.gestionInspecteur h3 {
  width: 100%;
  height: fit-content;
  padding: 5px;
  margin: 0;
  color: white;
  background-color: #243064;
  text-align: center;
  margin-bottom: 10px;
}

h4 {

  padding: 5px;
  margin: 0;
  color: rgb(255, 0, 0);

  border-bottom: 2px solid rgb(29, 26, 241);
  margin-bottom: 10px;
}

.gestionInspecteur .rechercher-table {
  width: 100%;


}

.gestionInspecteur .rechercher-table .rechercher {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-bottom: 10px;
}

.gestionInspecteur .rechercher-table .rechercher input:nth-child(1) {
  width: 50%;
  height: 40px;
  padding: 10px;
  border-radius: 15px;
  outline: 0;
  border: 1px solid #868686;
  font-size: 16px;
  color: #b8bdd1;
  margin-left: 5px;
  margin-right: 5px;
}

.gestionInspecteur .rechercher-table .rechercher input:nth-child(2) {
  width: 50%;
  height: 40px;
  padding: 10px;
  border-radius: 15px;
  outline: 0;
  border: 1px solid #868686;
  font-size: 16px;
  color: #b8bdd1;
  margin-left: 5px;
  margin-right: 5px;
}

.gestionInspecteur .rechercher-table .rechercher input:nth-child(3) {
  width: 120px;
  height: 40px;
  padding: 10px;
  border-radius: 15px;
  outline: 0;
  border: 0px;
  font-size: 16px;
  background-color: rgb(22, 207, 22);
  color: white;
  margin-left: 5px;
  margin-right: 5px;
  cursor: pointer;
}

.gestionInspecteur .rechercher-table .gestionaffaire {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.gestionInspecteur .rechercher-table .gestionaffaire input {
  margin-bottom: 10px;
  height: 40px;
  width: 60%;
  font-size: medium;
  padding: 5px;
}



.gestionInspecteur .rechercher-table table {
  width: 100%;
}

.gestionInspecteur .rechercher-table table tr {
  width: 100%;
}

.gestionInspecteur .rechercher-table table tr th {
  background-color: #243064;
  color: white;
  padding: 10px 0;
  border-right: 1px solid white;
}

.gestionInspecteur .rechercher-table table tr td {
  color: black;
  padding: 5px 2px;
  text-transform: capitalize;
}

table {
  border-collapse: collapse;
}

.gestionInspecteur .rechercher-table table tr:hover {
  background-color: #f5f5f5;
}

.gestionInspecteur .rechercher-table table tr td {
  cursor: pointer;
}

.gestionInspecteur .rechercher-table table tr td a {
  cursor: pointer;
  margin-right: 10px;
}

.fa-trash-can {
  color: red;
}

.fa-pen-to-square {
  color: blue;
}

.fa-circle-check {
  color: green;
}

.fa-download {
  color: black;
}

svg {
  font-size: 20px !important;
}


.gestionInspecteur .rechercher-table ul {
  width: 100%;
  height: fit-content;
  text-align: center;
  position: absolute;
  bottom: 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.gestionInspecteur .rechercher-table ul li {
  color: black;
  margin-left: 5px;
  cursor: pointer;
  font-size: 18px;
}

.gestionInspecteur .rechercher-table ul li:hover {
  color: red;
  margin-left: 5px;
  transition: 0.3s;
}


.gestionInspecteur .rechercher-table .deleteAll {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
}

.gestionInspecteur .rechercher-table .deleteAll input {
  width: fit-content;
  height: 40px;
  padding: 10px;
  background-color: red;
  color: white;
  border: 0;
  border-radius: 10px;
  cursor: pointer;
}




#app>div>div>div.menu-content>div.content>div>div>div.gestionaffaire>input[type=submit]:nth-child(5) {
  border: 1px solid #243064;
  outline: 0;
  color: black;
  font-size: 18px;
}

#app>div>div>div.menu-content>div.content>div>div>div.gestionaffaire>input[type=text]:nth-child(2) {
  border: 1px solid #b1b1b1;
  outline: 0;
  padding: 5px;
  color: black;
  font-size: 17px;
}

#app>div>div>div.menu-content>div.content>div>div>div.gestionaffaire>input[type=date]:nth-child(3) {
  border: 1px solid #b1b1b1;
  outline: 0;
  padding: 5px;
  color: black;
  font-size: 17px;
}

#app>div>div>div.menu-content>div.content>div>div>div.gestionaffaire>input.file {
  border: 1px solid #b1b1b1;
  outline: 0;
  padding: 5px;
  color: black;
  font-size: 17px;

}

#app>div>div>div.menu-content>div.content>div>div>div.gestionaffaire>input[type=submit]:nth-child(6) {
  border: 1px solid #b1b1b1;
  outline: 0;
  padding: 5px;
  color: black;
  font-size: 17px;
}



#inspecteurs>tr>td:nth-child(8)>a:nth-child(1)>svg {
  font-size: 20px;
  color: red;
}

#inspecteurs>tr>td:nth-child(8)>a>svg {
  font-size: 20px;
  color: blue;
}

.form-control {
  display: block;

  padding: .375rem .75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out
}

.form-control::-webkit-file-upload-button {
  padding: 10px;
  margin: -.375rem -.75rem;
  -webkit-margin-end: .75rem;
  margin-inline-end: .75rem;
  color: rgb(58, 58, 58);
  font-weight: 600;
  background-color: #e9e9e9;
  pointer-events: none;
  border: 0;

  -webkit-transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
  transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out
}


#app>div>div>div.menu-content>div.content>div>h3:nth-child(1) {
  width: 100%;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  margin: 0;

  color: white;
  background: linear-gradient(346deg, rgba(207, 31, 33, 1) 0%, rgba(24, 86, 161, 1) 100%);
  text-align: center;
  margin-bottom: 10px;
  padding: 10px;
  font-size: 25px;
}

#inspecteurs>tr>td {
  border-bottom: 1px solid #d1d1d1;
}




#app>div>div>div.menu-content>div.content>div>div>div.gestionaffaire>input.bonCammande {
  cursor: pointer;
  background-color: green;
  width: 200px;
  border-radius: 10px;
}

#app>div>div>div.menu-content>div.content>div>div>div.gestionaffaire>input.annuler {
  cursor: pointer;
  background-color: #ff0000d4;
  width: 200px;
  border-radius: 10px;
}

.pagination {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 10px auto;
  width: fit-content;
  flex-wrap: wrap;
  padding: 5px 10px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.pagination li {
  display: flex;
}

.pagination p {
  border: 1px solid rgb(151, 151, 151);
  margin: 0 4px;
  padding: 5px 10px;
  border-radius: 20px;
  cursor: pointer;
}

.pagination p:hover,
button:hover {
  background-color: #243064;
  color: white;
}




.pagination button {
  border: 0;
  padding: 5px;
  border: 1px solid rgb(170, 170, 170);
  margin-left: 15px;
  color: gray;
  cursor: pointer;
}

.pagination button:nth-child(1) {
  margin-right: 15px;
  margin-left: 0;
}

.ajouter {
  cursor: pointer;
  background-color: green;
  color: white;
  border: 0px;
  padding: 10px;
  border-radius: 10px;

}

.supprimer {
  cursor: pointer;
  background-color: red;
  color: white;
  border: 0px;
  padding: 10px;
  border-radius: 10px;
}
</style>