<template>
  <div class="gestionInspecteur">

    <h3 v-if="flagInsertIntervention == false">EN ATTENTE PRODUCTION</h3>

    <div v-if="flagInsertIntervention == false" class="rechercher-table">

      <div class="rechercher">

        <!-- Filter by Raison Social -->
        <select v-model="rechercher" @input="setRechercher($event)">
          <option v-for="item in clients" :key="item.raisonSocial" :value="item._id">{{ item.raisonSocial }}</option>
        </select>

        <!-- Filter by Year -->
        <select v-model="year" @input="setRechercherAnnee($event)">
          <option v-for="item in maxYears" :key="item" :value="item">{{ item }}</option>
        </select>

      </div>

      <p v-if="flagMsgBonCommande & succes == true" :class="{ succes: succes }">
        {{ msgBonCommande }}
      </p>

      <p v-if="flagMsgBonCommande && echec == true" :class="{ echec: echec }">
        {{ msgBonCommande }}
      </p>

      <div class="uplode" v-if="flagUplodeBonCammnade == true">
        <input type="file" multiple="multiple" class="file" placeholder="Fichier PDF" ref="file" @change="previewFile">
        <input type="submit" v-if="flagPreviewUplodeBonCammnade == true" value="Importer fichier"
          @click="enregitreBonCommande()">
      </div>

      <table id="inspecteurs">
        <tr>
          <th> </th>
          <th>Date</th>
          <th>Numéro d'offre</th>
          <th>Réf Client</th>
          <th>Raison sociale </th>
          <th>Mission</th>
          <th>Actions</th>
          <th colspan="7">Status</th>

        </tr>
        <tr v-for="(affaire, i) in filterAffaires" :key="affaire._id">

          <td>
            <input type="checkbox" :value="affaire._id" v-model="checkedClients" style="width: 20px;">
          </td>

          <td>{{ new Date(affaire.date).toLocaleDateString() }}</td>

          <td>{{ affaire.numeroAffaire }}</td>

          <td>
            <p v-for="(client, index) in clients" :key="index">{{
              affaire.numeroAffaire.substr(affaire.numeroAffaire.lastIndexOf("|") + 1, affaire.numeroAffaire.length) ===
                client.raisonSocial ? client.refClient : '' }}</p>
          </td>

          <td>
            <p v-for="(client, index) in clients" :key="index">{{
              affaire.numeroAffaire.substr(affaire.numeroAffaire.lastIndexOf("|") + 1, affaire.numeroAffaire.length) ===
                client.raisonSocial ? client.raisonSocial : '' }}</p>
          </td>

          <td v-if="affaire.numeroAffaire">
            <p v-for="(mission, index) in missions" :key="index"> {{ mission.numeroAffaire == affaire.numeroAffaire ?
              mission.codeMission : '' }}</p>
          </td>

          <td>
            <a v-show="affaire.bonCommande != 'false'" @click="deleteBonCammande(affaire._id, i)"><i
                class="fa-solid fa-backward-step"></i></a>
          </td>

          <td aria-label="Devise" v-if="affaire.nameFileAffaire != 'false'"
            style="background-color: white; margin : 0; padding: 0; padding-bottom: 5px; padding-left: 5px;"
            @click="displayDEVIS(affaire.nameFileAffaire)" title="Télécharger Devis">
            <p style="font-size: 20px; color:green; font-weight: bold;">P</p><i
              class="fa-solid fa-download" style="font-size: 20px; color:green;"></i>
          </td>
          <td v-if="affaire.nameFileAffaire == 'false'"
            style="background-color: white; margin : 0; padding: 0; padding-bottom: 5px; padding-left: 5px;">
            <p style="color: red; font-size: 20px;">P</p><i class="fa-solid fa-forward-step"
              style="font-size: 20px; color:red;"></i>
          </td>

          <td v-if="affaire.bonCommande != 'false'"
            style="background-color: white; margin : 0; padding: 0; padding-bottom: 5px; padding-left: 5px;"
            @click="displayBC(affaire.bonCommande)" title="Télécharger le Bon commande">
            <p style="font-size: 20px; color:green; font-weight: bold;">AP</p><i
              class="fa-solid fa-download" style="font-size: 20px; color:green;"></i>
          </td>
          <td v-if="affaire.bonCommande == 'false'" @click="bonCommande(affaire._id)"
            style="background-color: white; margin : 0; padding: 0; padding-bottom: 5px; padding-left: 5px;">
            <p style="font-size: 20px; color:red; font-weight: bold;">AP</p><i
              class="fa-solid fa-forward-step" style="font-size: 20px; color:red;"></i>
          </td>

          <td v-show="affaire.renseignerIntervention != 'false'" @click="generateIntervention(affaire._id)"
            title="Télécharger la fiche d'intervention"
            style="background-color: white; margin : 0; padding: 0; padding-bottom: 5px; padding-left: 5px;">
            <p style="font-size: 20px; color:green; font-weight: bold;">EP</p><i
              class="fa-solid fa-download" style="font-size: 20px; color:green;"></i>
          </td>
          <td v-show="affaire.renseignerIntervention == 'false'" @click="handelInsertIntervention(affaire._id)"
            title="Saisir la fiche d'intervention"
            style="background-color: white; margin : 0; padding: 0; padding-bottom: 5px; padding-left: 5px;">
            <p style="font-size: 20px; color:red; font-weight: bold;">EP</p><i
              class="fa-solid fa-forward-step" style="font-size: 20px; color:red;"></i>
          </td>


          <td v-if="affaire.importRapport != 'false'"
            style="background-color: white; margin : 0; padding: 0; padding-bottom: 5px; padding-left: 5px;">
            <p style="font-size: 20px; color:green; font-weight: bold;">RE</p><i
              class="fa-solid fa-download" style="font-size: 20px; color:white;"></i>
          </td>
          <td v-if="affaire.importRapport == 'false'"
            style="background-color: white; margin : 0; padding: 0; padding-bottom: 5px; padding-left: 5px;">
            <p style="font-size: 20px; color:red; font-weight: bold;">RE</p><i
              class="fa-solid fa-forward-step" style="font-size: 20px; color:white;"></i>
          </td>

          <td v-if="affaire.be != 'false'"
            style="background-color: white; margin : 0; padding: 0; padding-bottom: 5px; padding-left: 5px;"
            @click="showBE(affaire.be)">
            <p style="font-size: 20px; color:green; font-weight: bold;">BE</p><i
              class="fa-solid fa-download" style="font-size: 20px; color:green;"></i>
          </td>
          <td v-if="affaire.be == 'false'"
            style="background-color: white; margin : 0; padding: 0; padding-bottom: 5px; padding-left: 5px;">
            <p style="font-size: 20px; color:red; font-weight: bold;">BE</p><i
              class="fa-solid fa-forward-step" style="font-size: 20px; color:white;"></i>
          </td>

          <td v-if="affaire.facture != 'false'"
            style="background-color: white; margin : 0; padding: 0; padding-bottom: 5px; padding-left: 5px;"
            @click="showFacture(affaire.facture)">
            <p style="font-size: 20px; color:green; font-weight: bold;">FA</p><i
              class="fa-solid fa-download" style="font-size: 20px; color:green;"></i>
          </td>
          <td v-if="affaire.facture == 'false'"
            style="background-color: white; margin : 0; padding: 0; padding-bottom: 5px; padding-left: 5px;">
            <p style="font-size: 20px; color:red; font-weight: bold;">FA</p><i
              class="fa-solid fa-forward-step" style="font-size: 20px; color:white;"></i>
          </td>

          <td v-show="affaire.emise != 'false'"
            style="background-color: white; margin : 0; padding: 0; padding-bottom: 5px; padding-left: 5px;"
            @click="showEmise(affaire.emise)">
            <p style="font-size: 20px; color:green; font-weight: bold;">EN</p><i
              class="fa-solid fa-download" style="font-size: 20px; color:green;"></i>
          </td>
          <td v-show="affaire.emise == 'false'"
            style="background-color: white; margin : 0; padding: 0; padding-bottom: 5px; padding-left: 5px;"
            @click="handelInsertEmise(affaire._id, i)">
            <p style="font-size: 20px; color:red; font-weight: bold;">EN</p><i
              class="fa-solid fa-forward-step" style="font-size: 20px; color:white;"></i>
          </td>

        </tr>
      </table>

      <div class="deleteAll" v-show="checkedClients.length > 1">
        <input type="submit" value="Supprimer tout" @click="deleteClients()">
      </div>

    </div>


    <ul class="pagination" v-if="flagInsertIntervention == false">
      <button @click="selectPageLeft()"><i class="fa-solid fa-backward"></i></button>
      <li v-for="n in numberPage" :key="n" @click="selectPage(n)">
        <p v-show="numberPageSelect[n - 1] == false">{{ n }}</p>
        <p v-show="numberPageSelect[n - 1] == true" style="background-color: #0300db; color: white;">{{ n }}</p>
      </li>
      <button @click="selectPageRight()"><i class="fa-solid fa-forward"></i></button>
    </ul>

    <!--  Start Info Client   -->
    <InsertIntervention :affaireId="affaireId" v-if="flagInsertIntervention == true" />
    <!--  End Info Client   -->

  </div>

</template>

<script>
import Service from "../../../../Service";
import InsertIntervention from "../Affaire/InsertIntervention.vue";
// import InfoClient from "./InfoClient.vue";
export default {

  name: "gestionInterlocuteur",
  components: {
    InsertIntervention
  },

  data() {
    return {
      year: null,
      numberPageSelect: [],
      pageIndex: 0,
      itemsPerPage: 10,
      numberPage: null,
      preAffaires: [],
      flagInsertIntervention: false,
      flagMsgBonCommande: false,
      msgBonCommande: null,
      flagPreviewUplodeBonCammnade: false,
      file: null,
      affaireId: null,
      flagUplodeBonCammnade: false,
      rechercher: null,
      affaires: [],
      missions: [],
      clients: [],
      maxYears: [],
      checkedClients: [],
      msg: null,
      flagEditClient: false,
      flagInfoClient: false,
      infoClient: [],
      succes: null,
      echec: null
    };
  },
  methods: {

    // select last page
    selectPageLeft() {

      // get first page
      for (let i = 0; i < this.numberPage; i++) {
        this.numberPageSelect[i] = false;
      }

      this.numberPageSelect[0] = true;
      this.affaires = [];

      for (let i = 0 * this.itemsPerPage; i < (0 * this.itemsPerPage) + this.itemsPerPage; i++) {
        if (this.preAffaires[i] != undefined) {
          this.affaires.push(this.preAffaires[i]);
        }
      }

    },

    // select first page
    selectPageRight() {

      // get first page
      for (let i = 0; i < this.numberPage; i++) {
        this.numberPageSelect[i] = false;
      }

      const indice = this.numberPage - 1;
      this.numberPageSelect[indice] = true;
      this.affaires = [];

      for (let i = indice * this.itemsPerPage; i < (indice * this.itemsPerPage) + this.itemsPerPage; i++) {
        if (this.preAffaires[i] != undefined) {
          this.affaires.push(this.preAffaires[i]);
        }
      }

    },

    // select spec page
    selectPage(value) {

      // vide liste affaires
      this.affaires = [];

      // set index specfique
      const select = value - 1;

      // change current select for change color
      for (let i = 0; i < this.numberPage; i++) {
        this.numberPageSelect[i] = false;
      }

      // select page and set value
      this.numberPageSelect[select] = true;

      // set affaires by index select
      for (let i = select * this.itemsPerPage; i < (select * this.itemsPerPage) + this.itemsPerPage; i++) {
        if (this.preAffaires[i] != undefined) {
          this.affaires.push(this.preAffaires[i]);
        }
      }

    },

    // Delete BonCammande
    deleteBonCammande(affaireId, index) {

      Service.DeleteBonCammande(affaireId)
        .then((result) => {
          if (result) {
            this.affaires.splice(index, 1);

          }
        })
        .catch((error) => {
          console.log(error.message);
        });
    },

    // function for downlaod file forma doc word
    downloadFile(response) {
      var newBlob = new Blob([response.data], {
        type: "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
      });
      if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveOrOpenBlob(newBlob);
        return;
      }
      const data = window.URL.createObjectURL(newBlob);
      var link = document.createElement("a");
      link.href = data;
      link.download = "resume.docx";
      link.click();
      setTimeout(function () {
        window.URL.revokeObjectURL(data);
      }, 100);
    },

    // Generate boncommande
    generateBD(affaireId) {
      Service.generateBD(affaireId)
        .then((res) => {
          if (res.data.msg == true) {
            Service.downloadBD()
              .then((response) => {
                this.downloadFile(response);
              })
              .catch((error) => {
                console.error(`HTTP error: ${error.name} => ${error.message}`);
                throw "fail request at: GET /refreshtime";
              });
          }
        })
        .catch((error) => {
          console.error(`HTTP error: ${error.name} => ${error.message}`);
        });
    },  

    // display BonCammande
    displayBC(filename) {
      Service.displayBC(filename)
        .then((data) => {
          console.log(data);
        })
        .catch((error) => {
          console.error(`HTTP error: ${error.name} => ${error.message}`);
          throw "fail request at: GET /refreshtime";
        });
    },

    // display DEVIS
    displayDEVIS(filename) {
      console.log(filename);
      Service.displayDEVIS(filename)
        .then((data) => {
          console.log(data);
        })
        .catch((error) => {
          console.error(`HTTP error: ${error.name} => ${error.message}`);
          throw "fail request at: GET /refreshtime";
        });
    },

    //handel insert Intervention
    handelInsertIntervention(affaireId) {
      this.affaireId = affaireId
      this.flagInsertIntervention = true;
    },

    // Generate fiche Intervention
    generateIntervention(affaireId) {
      Service.generateIntervention(affaireId)
        .then((res) => {
          if (res.data.msg == true) {
            Service.displayIntervention()
              .then((response) => {
                this.downloadFile(response);
              })
              .catch((error) => {
                console.error(`HTTP error: ${error.name} => ${error.message}`);
                throw "fail request at: GET /refreshtime";
              });
          }
        })
        .catch((error) => {
          console.error(`HTTP error: ${error.name} => ${error.message}`);
        });
    },

    // Enregistre Bon Cammande
    enregitreBonCommande() {

      Service.enregitreBonCommande(this.file, this.affaireId)
        .then(() => {
          this.msgBonCommande = "Bon Cammande a été enregistré avec succès"
          this.flagMsgBonCommande = true;
          this.succes = true;
          this.echec = false;
          setTimeout(() => {
            this.msgBonCommande = ""
            this.flagMsgBonCommande = false;
            this.succes = null;
            this.echec = null;
            this.$router.go(this.$router.currentRoute);

          }, 5000)
        })
        .catch((error) => {

          this.msgBonCommande = error.message;
          this.flagMsgBonCommande = true;
          this.succes = false;
          this.echec = true;

          console.error(`HTTP error: ${error.name} => ${error.message}`);
          throw "fail request at: GET /refreshtime";
        });
    },
    // preciew file
    previewFile() {
      this.file = this.$refs.file.files[0];
      if (this.file) {
        this.flagPreviewUplodeBonCammnade = true;
      }
    },
    // create bon cammnde
    bonCommande(affaireId) {
      // prompt(message, defaultText, title, type, reverseButton)
      if (this.flagUplodeBonCammnade == true) {
        this.affaireId = null;
        this.flagUplodeBonCammnade = false

      } else {
        this.affaireId = affaireId;
        this.flagUplodeBonCammnade = true
      }
    },
    // delete more one client (clients)
    deleteClients() {
      const idClients = new Array();
      for (let i = 0; i < this.checkedClients.length; i++) {
        idClients[i] = this.checkedClients[i];
        this.clients.splice(this.checkedClients[i], 1);

        Service.deleteClient(idClients[i])
          .then((result) => {
            this.msg = result.data.msg;
          })
          .catch((error) => {
            this.msg = error.message;
            console.error(`HTTP error: ${error.name} => ${error.message}`);
            throw "fail request at: GET /refreshtime";
          });
      }


    },
    // delete one client
    deleteClient(i) {
      const clientId = this.clients[i]._id;
      this.clients.splice(i, 1);
      Service.deleteClient(clientId)
        .then((result) => {

          this.msg = result.data.msg;
        })
        .catch((error) => {
          this.msg = error.message;
          console.error(`HTTP error: ${error.name} => ${error.message}`);
          throw "fail request at: GET /refreshtime";
        });
    },

    // edit one client
    editClient(i) {
      this.infoClient.push(this.clients[i])
      this.flagInfoClient = false;
      this.flagEditClient = true;
    },

    // edit info one client
    informationClient(i) {
      this.infoClient.push(this.clients[i])
      this.flagEditClient = false;
      this.flagInfoClient = true;
    },

    // set value for filter affaires by year
    setRechercherAnnee(event) {
      this.year = event.target.value;
      this.handelAffairesCurrent(this.year);
    },

    // set value for filetr by name company
    setRechercher(event) {
      this.rechercher = event.target.value;
      this.handelAffairesCurrentById(this.rechercher);
    },

    // handel function for filter affaires by Id
    handelAffairesCurrentById(idRaisonSocial) {

      this.numberPage = null;
      this.numberPageSelect = [];
      this.preAffaires = [];
      this.affaires = [];
      this.missions = [];
      this.clients = [];

      // read all Affaires
      Service.readAllAffairesByRaisonSocial(idRaisonSocial)
        .then((result) => {

          for (let i = 0; i < result.data.result.length; i++) {
            if (result.data.result[i].bonCommande != 'false' && result.data.result[i].renseignerIntervention == "false") {
              this.preAffaires.push(result.data.result[i]);
            }
          }

          if (this.preAffaires.length > 10) {

            this.numberPage = Math.ceil((this.preAffaires.length) / this.itemsPerPage);
            for (let i = 0; i < this.numberPage; i++) {
              this.numberPageSelect.push(false);
            }

            this.numberPageSelect[0] = true;

          } else {
            this.numberPage = 1;
            this.numberPageSelect[0] = true;
          }

          // numberPageSelect
          for (let i = this.pageIndex * this.itemsPerPage; i < (this.pageIndex * this.itemsPerPage) + this.itemsPerPage; i++) {
            if (this.preAffaires[i] != undefined) {
              this.affaires.push(this.preAffaires[i]);
            }

          }


        })
        .catch((error) => {
          this.msg = error.message;
          console.error(`HTTP error: ${error.name} => ${error.message}`);
          throw "fail request at: GET /refreshtime";
        });

      // Read all missions
      Service.readAllMissions()
        .then((result) => {
          this.missions = result.data.missions;
        })
        .catch((error) => {

          this.msg = error.message;
          console.error(`HTTP error: ${error.name} => ${error.message}`);
          throw "fail request at: GET /refreshtime";
        });



      // Read all clients and check rapport exist or no
      Service.readClient()
        .then((result) => {
          this.clients = result.data.clients;
          // check rapports exist and valide
          Service.checkRapports()
            .then((result) => {
              var objectsFound = [];
              // search db atlas cloud
              for (let objectNumber in result.clients) {
                var nomSociete = result.clients[objectNumber].nomSociete;
                var idSociete = result.clients[objectNumber]._id;
                //search db local
                this.clients.forEach(element => {
                  if (element.raisonSocial == nomSociete) {
                    // serach rappport with consition (confirmation & clientId)
                    result.rapports.forEach(element => {
                      if (element.confirmation == 1 && element.clientId == idSociete) {
                        objectsFound.push(nomSociete);
                      }
                    })

                  }
                });
              }

            })
            .catch((error) => {
              console.log(error)
            });

        })
        .catch((error) => {
          this.msg = error.message;
          console.error(`HTTP error: ${error.name} => ${error.message}`);
          throw "fail request at: GET /refreshtime";
        });

    },

    // handel function for filter affaires by name affaire
    handelAffairesCurrent(year) {

      this.numberPage = null;
      this.numberPageSelect = [];
      this.preAffaires = [];
      this.affaires = [];
      this.missions = [];
      this.clients = [];

      // read all Affaires
      Service.readAllAffaires(year)
        .then((result) => {

          for (let i = 0; i < result.data.result.length; i++) {
            if (result.data.result[i].bonCommande != 'false' && result.data.result[i].renseignerIntervention == "false") {
              this.preAffaires.push(result.data.result[i]);
            }
          }

          if (this.preAffaires.length > 10) {

            this.numberPage = Math.ceil((this.preAffaires.length) / this.itemsPerPage);
            for (let i = 0; i < this.numberPage; i++) {
              this.numberPageSelect.push(false);
            }

            this.numberPageSelect[0] = true;

          } else {
            this.numberPage = 1;
            this.numberPageSelect[0] = true;
          }

          // numberPageSelect
          for (let i = this.pageIndex * this.itemsPerPage; i < (this.pageIndex * this.itemsPerPage) + this.itemsPerPage; i++) {
            if (this.preAffaires[i] != undefined) {
              this.affaires.push(this.preAffaires[i]);
            }

          }

        })
        .catch((error) => {
          this.msg = error.message;
          console.error(`HTTP error: ${error.name} => ${error.message}`);
          throw "fail request at: GET /refreshtime";
        });


      // Read all missions
      Service.readAllMissions()
        .then((result) => {
          this.missions = result.data.missions;
        })
        .catch((error) => {

          this.msg = error.message;
          console.error(`HTTP error: ${error.name} => ${error.message}`);
          throw "fail request at: GET /refreshtime";
        });



      // Read all clients and check rapport exist or no
      Service.readClient()
        .then((result) => {
          this.clients = result.data.clients;
          // check rapports exist and valide
          Service.checkRapports()
            .then((result) => {
              var objectsFound = [];
              // search db atlas cloud
              for (let objectNumber in result.clients) {
                var nomSociete = result.clients[objectNumber].nomSociete;
                var idSociete = result.clients[objectNumber]._id;
                //search db local
                this.clients.forEach(element => {
                  if (element.raisonSocial == nomSociete) {
                    // serach rappport with consition (confirmation & clientId)
                    result.rapports.forEach(element => {
                      if (element.confirmation == 1 && element.clientId == idSociete) {
                        objectsFound.push(nomSociete);
                      }
                    })

                  }
                });
              }

            })
            .catch((error) => {
              console.log(error)
            });

        })
        .catch((error) => {
          this.msg = error.message;
          console.error(`HTTP error: ${error.name} => ${error.message}`);
          throw "fail request at: GET /refreshtime";
        });

    },

  },

  computed: {
    filterAffaires() {
      return this.affaires.filter((item) => {
        if (!this.rechercher) {
          return item;
        } else {
          if (item != undefined) {
            return !item.numeroAffaire.toLowerCase().toString().indexOf(this.rechercher.toLowerCase().toString());
          }
        }



      })
    }
  },

  created() {

    // set array years 2019 ----- Current year
    const date = new Date();
    for (let j = date.getFullYear(); j >= 2019; j--) {
      this.maxYears.push(j);
    }

    // read all Affaires
    Service.readAllAffaires(null)
      .then((result) => {

        result.data.result.forEach((element) => {
          if (element.bonCommande != 'false' && element.renseignerIntervention == "false") {
            this.preAffaires.push(element);
          }
        });

        if (this.preAffaires.length > 10) {

          this.numberPage = Math.ceil((this.preAffaires.length) / this.itemsPerPage);
          for (let i = 0; i < this.numberPage; i++) {
            this.numberPageSelect.push(false);
          }

          this.numberPageSelect[0] = true;

        } else {
          this.numberPage = 1;
          this.numberPageSelect[0] = true;
        }


        for (let i = this.pageIndex * this.itemsPerPage; i < (this.pageIndex * this.itemsPerPage) + this.itemsPerPage; i++) {
          if (this.preAffaires[i] != undefined) {
            this.affaires.push(this.preAffaires[i]);
          }
        }

      })
      .catch((error) => {
        this.msg = error.message;
        console.error(`HTTP error: ${error.name} => ${error.message}`);
        throw "fail request at: GET /refreshtime";
      });

    // Read all missions
    Service.readAllMissions()
      .then((result) => {
        this.missions = result.data.missions;
      })
      .catch((error) => {

        this.msg = error.message;
        console.error(`HTTP error: ${error.name} => ${error.message}`);
        throw "fail request at: GET /refreshtime";
      });

    // Read all clients and check rapport exist or no
    Service.readClient()
      .then((result) => {
        this.clients = result.data.clients;
        // check rapports exist and valide
        Service.checkRapports()
          .then((result) => {
            var objectsFound = [];
            // search db atlas cloud
            for (let objectNumber in result.clients) {
              var nomSociete = result.clients[objectNumber].nomSociete;
              var idSociete = result.clients[objectNumber]._id;
              //search db local
              this.clients.forEach(element => {
                if (element.raisonSocial == nomSociete) {
                  // serach rappport with consition (confirmation & clientId)
                  result.rapports.forEach(element => {
                    if (element.confirmation == 1 && element.clientId == idSociete) {
                      objectsFound.push(nomSociete);
                    }
                  })

                }
              });
            }

          })
          .catch((error) => {
            console.log(error)
          });

      })
      .catch((error) => {

        this.msg = error.message;
        console.error(`HTTP error: ${error.name} => ${error.message}`);
        throw "fail request at: GET /refreshtime";
      });


  }

};
</script>

<style scoped>
.succes {
  background-color: green;
  color: white;
  padding: 10px;
  height: fit-content;
  width: 100%;
}

.echec {
  background-color: red;
  color: white;
  padding: 10px;
  height: fit-content;
  width: 100%;
}

.gestionInspecteur {
  width: 100%;
  height: 100%;
  padding: 2px;
  margin: 0px;
  position: relative;
}

.gestionInspecteur h3 {
  width: 100%;
  height: fit-content;
  padding: 5px;
  margin: 0;
  color: white;
  background-color: #243064;
  text-align: center;
  margin-bottom: 10px;
}


.gestionInspecteur .rechercher-table {
  width: 100%;
}

.gestionInspecteur .rechercher-table .rechercher {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-bottom: 10px;

}

.gestionInspecteur .rechercher-table .rechercher select {
  width: 10%;
  height: 40px;
  padding: 10px;
  border-radius: 15px;
  outline: 0;
  border: 1px solid #868686;
  font-size: 16px;
  color: #b8bdd1;
  margin-left: 5px;
  margin-right: 5px;
}


.gestionInspecteur .rechercher-table .uplode {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 10px;

}

.gestionInspecteur .rechercher-table .uplode input {
  margin-bottom: 10px;
  height: 40px;
  width: 30%;
}



.gestionInspecteur .rechercher-table table {
  width: 100%;
}

.gestionInspecteur .rechercher-table table tr {
  width: 100%;
}

.gestionInspecteur .rechercher-table table tr th {
  background-color: #243064;
  color: white;
  padding: 10px 2px;
  border-right: 1px solid white;
}

table {
  border-collapse: collapse;
}

.gestionInspecteur .rechercher-table table tr td {
  color: black;
  padding: 5px;
}

.gestionInspecteur .rechercher-table table tr:hover {
  background-color: #f5f5f5;

}

.gestionInspecteur .rechercher-table table tr td {
  cursor: pointer;
}

.gestionInspecteur .rechercher-table table tr td a {
  cursor: pointer;
  margin-left: 10px;
  margin-right: 10px;
}

.fa-trash-can {
  color: red;
}

.fa-pen-to-square {
  color: blue;
}

.fa-circle-check {
  color: green;
}

.fa-download {
  color: black;
}


.gestionInspecteur .rechercher-table ul {
  width: 100%;
  height: fit-content;
  text-align: center;
  position: absolute;
  bottom: 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.gestionInspecteur .rechercher-table ul li {
  color: black;
  margin-left: 5px;
  cursor: pointer;
  font-size: 20px;
}

.gestionInspecteur .rechercher-table ul li:hover {
  color: red;
  margin-left: 5px;
  transition: 0.3s;
}


.gestionInspecteur .rechercher-table .deleteAll {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
}

.gestionInspecteur .rechercher-table .deleteAll input {
  width: 50%;
  height: 40px;
  background-color: red;
  color: white;
  border: 0;
}

#inspecteurs>tr>td:nth-child(7)>a:nth-child(1)>svg {
  font-size: 20px;
  color: red;
}

#inspecteurs>tr>td:nth-child(7)>a>svg {
  font-size: 20px;
  color: blue;
}

#inspecteurs>tr>td:nth-child(7)>a:nth-child(3)>svg {
  font-size: 20px;
  color: green;
}

#app>div>div>div.menu-content>div.content>div {
  background-color: white;
}

#inspecteurs>tr>td {
  border-bottom: 1px solid #a0a0a0;
}

#app>div>div>div.menu-content>div.content>div>h3 {

  width: 100%;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  margin: 0;

  color: white;
  background: linear-gradient(346deg, rgba(207, 31, 33, 1) 0%, rgba(24, 86, 161, 1) 100%);
  text-align: center;
  margin-bottom: 10px;
  padding: 10px;
  font-size: 20px;

}


.pagination {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 10px auto;
  width: fit-content;
  flex-wrap: wrap;
  padding: 5px 10px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.pagination p {
  border: 1px solid rgb(151, 151, 151);
  margin: 0 4px;
  padding: 5px 10px;
  border-radius: 20px;
  cursor: pointer;
}

.pagination p:hover,
button:hover {
  background-color: #243064;
  color: white;
}




.pagination button {
  border: 0;
  padding: 5px;
  border: 1px solid rgb(170, 170, 170);
  margin-left: 15px;
  color: gray;
  cursor: pointer;
}

.pagination button:nth-child(1) {
  margin-right: 15px;
  margin-left: 0;

}
</style>