<template>
  <div class="creationClient">

    <h3><i class="fa-solid fa-receipt"></i> CRÉATION FACTURE </h3>

    <h3 class="titre-client">DATE BORDEREAU D'ENVOI : </h3>

    <div class="formCreation">
      <div>
        <input type="text" v-model="facture.dateCreation">
      </div>
    </div>



    <h3 class="titre-client">REFERENCE AFFAIRE : </h3>

    <div class="formCreation">


      <div>
        <label for="adresse">Réf. Client</label>
        <input type="text" disabled v-model="facture.refClient">
      </div>

      <div>
        <label for="adresse">N°. Affaire</label>
        <input type="text" disabled v-model="facture.numeroAffaire">
      </div>


      <div>
        <label for="ville">Libellée – Mission</label>
        <input v-for="mission in facture.missions" :key="mission._id" type="text" v-model="mission.codeMission">
      </div>

      <div>
        <label for="adresse"></label>
        <input type="hidden" disabled v-model="facture.affaireId">
      </div>

    </div>


    <h3 class="titre-interlocuteur"> FACTURE N°:  {{ facture.numeroFACTURE }}  </h3>

    <div class="formCreation">

        <div>
            <label for="Référence facture">Référence facture</label>
            <input type="text" v-model="facture.numeroFACTURE">
        </div>

        <div>
            <label for=""></label>
            <input type="hidden">
        </div>

        <div>
            <label for="nom">Facturation</label>
            <input  type="date" @input="handelFacturation($event)">
        </div>

        <div>
            <label for="nom">Echéance</label>
            <input  type="date" @input="handelEcheance($event)">
        </div>

    </div>

    <div class="formCreation">
        <div>
            <label for="nom">Nom & Raison Sociale</label>
            <input type="text" v-model="facture.raisonSocial">
        </div>

        <div>
            <label for="nom">Adresse</label>
            <input disabled type="text" v-model="facture.adresse">
        </div>

        <div>
            <label for="nom">Téléphone</label>
            <input type="text" v-model="facture.telephone">
        </div>

        <div>
            <label for="nom">Adresse d'Envoie</label>
            <input type="text" v-model="facture.adresseEnvoi">
        </div>

        <div>
            <label for="nom">N° du BC de Client :</label>
            <input disabled type="text" v-model="facture.numeroBC">
        </div>

        <div>
            <label for="nom">N° ICE</label>
            <input type="text" v-model="facture.numeroICE">
        </div>

    </div>

    <h3 class="titre-interlocuteur"> Informations des missions : </h3>



        <table id="inspecteurs">
              <tr>
                <th>N°</th>
                <th>Désignation</th>
                <th>Prix HT</th>
                <th>Qté</th>
                <th>Remise</th>
                <th>Unite</th>
                <th>Prix HT Finale</th>
                <th>Devis</th>

              </tr>
              <tr v-for="(mission, index) in facture.missions" :key="index">

                <td>
                  <ul>
                    <li>{{ index + 1 }}</li>
                  </ul>
                </td>

                <td>
                  <ul>
                    <li>
                      <input class="input-titre" type="text" v-model="mission.titre" />
                    </li>
                  </ul>
                </td>

                <td>
                  <ul>
                    <li>
                      <input class="input-prixHT" type="text" v-model="mission.prixHt" @input="claculPrixHt" />
                    </li>
                  </ul>
                </td>

                <td>
                  <ul>
                    <li>
                      <input class="input-quantite" type="text" v-model="mission.quantite" @input="calculQuantite" />
                    </li>
                  </ul>
                </td>

                <td>
                  <ul>
                    <li>
                      <input class="input-remise" type="text" v-model="mission.remise" @input="calculRemise"/>
                    </li>
                  </ul>
                </td>

                <td>
                  <ul>
                    <li>
                      <input class="input-unite" type="text" v-model="mission.unite" />
                    </li>
                  </ul>
                </td>

                <td>
                  <ul>
                    <li>
                      <input class="input-unite" type="text" v-model="mission.prixHtFinale" />
                     </li>
                  </ul>
                </td>

                <td>
                  <ul>
                    <li>
                      <input class="input-unite" type="text" v-model="mission.devis" />
                     </li>
                  </ul>
                </td>

              </tr>
        </table>

        <div class="ajouter-supprimer">
          <button class="ajouter" @click="ajouter()">Ajouter</button>
          <button class="supprimer" @click="supprimer()">Supprimer</button>
        </div>

        <table id="inspecteurs" class="synthese">

              <tr>
                <th colspan="2">SYNTHESE</th>
              </tr>

              <tr>
                <td>
                    PRIX TOTAL HT
                </td>
                <td>
                    <input type="text" disabled v-model="facture.prixTotalHT">
                </td>
              </tr>

              <tr>
                <td>
                    TVA
                </td>
                <td>
                  <select @input="selectTva($event)">
                    <option value="0">0 %</option>
                    <option value="8">8 %</option>
                    <option value="10">10 %</option>
                    <option value="20">20 %</option>
                  </select>
                </td>
              </tr>

              <tr>
                <td>
                   PRIX TOTAL TTC
                </td>
                <td>
                    <input type="text" disabled v-model="facture.prixTotalTTC">
                </td>
              </tr>

              <tr>
                <td>
                   ARRETEE LA PRESENTE FACTURE A LA SOMME DE :
                </td>
                <td>
                    <input type="text" v-model="facture.prixLettre">
                </td>
              </tr>

            </table>


    <div class="enregistre">
      <button @click="apercuFACTURE()">Télècharger la facture</button>
      <button @click="enregistreFACTURE()"><i class="fa-solid fa-download"></i>Enregistrer la facture</button>
    </div>

    <ModalLanding v-if="flagModalLanding"/>
    <ModalErrors :errors="errors" v-if="flagModalErrors"/>

  </div>
</template>

<script>
import Service from "../../../../Service";
import ModalLanding from "@/views/components/ModalLanding.vue"
import ModalErrors from "@/views/components/ModalErrors.vue"

export default {
    props: {
      numeroAffaire: String,
    },

    components: {
      ModalLanding,
      ModalErrors
    },

    data() {
        return {
            flagForm : true,
            errors : [],
            flagModalLanding : false,
            flagModalErrors : false,
            tva : 0,
            deDate: null,
            aDate: null,
            commentaire : null,
            inspecteur :null,
            chargeAffaire: null,
            missions: [],
            client: {
                    raisonSocial : null,
                    adresse : null,
                    ville : null,
                    codePostal : null,
                    pays : null,
                    email : null,
                    ice : null,
                    refClient : null,
                    _id : null
            },
            interlocuteur: {
                    nom : null,
                    prenom : null,
                    email : null,
                    codePostal : null,
                    fonction : null,
                    telephone : null,
                    clientId : null,
                    _id: null
            },
            mission: {
                    codeMission : null,
                    apparteurAffaire : null,
                    typeMission : null,
                    ht : null,
                    tva : null,
                    qte : null,
                    prix : null,
                    numeroAffaire : null,
            },
            affaire : {
                numeroAffaire : null,
                apporteurAffaire : null,
                bonCommande : null,
                renseignerIntervention: null,
                importRapport: null,
                be :null,
            },
            intervention :  {
                  nameInterlocuteur : null,
                  telephoneInterlocuteur : null,
                  raisonSocial : null,
                  adresseClient : null,
                  commentaire: null,
                  deDate: null,
                  aDate: null,
                  nameInspecteur: null,
                  chargeAffaire: null,
                  missions: null,
                  interlocuteurId: null,
                  clientId: null,
                  numeroAffaire : null
            },

            facture : {
                numeroFACTURE: null,
                refClient : null,
                numeroAffaire : null,
                missions : [],
                facturation : null,
                echeance : null,
                raisonSocial : null,
                adresse : null,
                telephone : null,
                adresseEnvoi : null,
                numeroBC : null,
                numeroICE : null,
                dateCreation : null,
                affaireId : null,
                tva: 0,
                prixTotalHT: 0,
                prixTotalTTC: 0,
                prixLettre: null,
            }
    };
},

methods: {

    // download file extention DOCX
    downloadFile(response) {
        var newBlob = new Blob([response.data], {
            type: "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
        });
        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
            window.navigator.msSaveOrOpenBlob(newBlob);
            return;
        }
        const data = window.URL.createObjectURL(newBlob);
        var link = document.createElement("a");
        link.href = data;
        link.download = "resume.docx";
        link.click();
        setTimeout(function() {
            window.URL.revokeObjectURL(data);
        }, 100);
    },

    calculFacture() {

      let sommePrixHT = 0;

      this.facture.missions.forEach((el, index) => {

          const prixHt = Number(this.facture.missions[index].prixHt);
          const quantite = Number(this.facture.missions[index].quantite) == 0 ? 1 : Number(this.facture.missions[index].quantite);
          const remise = Number(this.facture.missions[index].remise)

          if(remise != 0) {
            const percentage = (remise / 100) * (prixHt * quantite)
            this.facture.missions[index].prixHtFinale = (prixHt * quantite) - percentage;
          }

          if(remise == 0) {
            this.facture.missions[index].prixHtFinale = (prixHt * quantite);
          }

          sommePrixHT = sommePrixHT + this.facture.missions[index].prixHtFinale;

      });

      this.facture.prixTotalHT = sommePrixHT;
      this.facture.prixTotalTTC = this.facture.prixTotalHT + (this.facture.prixTotalHT * (this.facture.tva)/100);
    },

    claculPrixHt() {
      this.calculFacture();
    },

    calculQuantite() {
      this.calculFacture();
    },

    calculRemise() {
      this.calculFacture();
    },

    ajouter() {
        this.facture.missions.push({
          titre : "",
          prixHt : 0,
          quantite : 0,
          remise : 0,
          prixHtFinale : 0,
          devis : this.facture.missions[0].devis
        });
    },

    supprimer() {
      this.facture.missions.pop();
    },

    selectTva(event) {
      this.facture.tva = event.target.value;
      this.tva = event.target.value;
      this.facture.prixTotalTTC = this.facture.prixTotalHT + ((this.facture.prixTotalHT * this.facture.tva)/100);
    },

    finishModal() {
      setTimeout(() => {
          this.flagModalLanding = false;
          this.flagModalErrors = false;
          this.errors = [];
          this.message = null;
      }, 8000);
    },

    apercuFACTURE() {

      this.flagForm = true;

      // check not empty
      for(const [key, value] of Object.entries(this.facture)) {
          if(value == null || value == "" || value == undefined) {
            console.log(key, value);
            this.flagForm = false;
            break;
          }
      } 

      if(!this.flagForm){
          this.flagModalErrors = true;
          this.errors.push("Veuillez saisir tout les champs");
          this.finishModal();
      } else {
          // handel landing Modal
          this.flagModalLanding = true;
          Service.apercuFACTURE(this.facture)
          .then((result) => {

            if(result.data.msg == true) {
                Service.downloadFacture()
                .then((response) => {
                    this.flagModalLanding = false;
                    this.finishModal();
                    return this.downloadFile(response);
                })
                .catch((error) => {
                    this.flagModalErrors = true;
                    this.errors.push(error.message);
                    this.finishModal();
                });
            }
          })
          .catch((err) => {
            console.log(err)
          });
      }
    },

    enregistreFACTURE() {

      this.flagForm = true;
      // check not empty
      for(const [key, value] of Object.entries(this.facture)) {
          if(value == null || value == "" || value == undefined) {
            console.log(key, value);
            this.flagForm = false;
            break;
          }
      } 

      if(!this.flagForm){
          this.flagModalErrors = true;
          this.errors.push("Veuillez saisir tout les champs");
          this.finishModal();
      } else {

          Service.enregistreFACTURE(this.facture)
          .then(() => {
                      // handel landing Modal
                      this.flagModalLanding = true;
                      //for delete landing after succes
                      this.finishModal();
                      return this.$router.go(this.$router.currentRoute);
          })
          .catch((err) => {
            console.log(err)
          });
      }
    },

    handelFacturation(event) {
      this.facture.facturation = event.target.value;
    },

    handelEcheance(event) {
      this.facture.echeance = event.target.value;
    }

},

created() {

      var today = new Date();
      var dd = String(today.getDate()).padStart(2, '0');
      var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
      var yyyy = today.getFullYear();
      const numberFacture = 46
      this.facture.dateCreation = `Le ${dd}-${mm}-${yyyy}`;

    // Get last Numero BD
    Service.getLastFACTURE()
    .then((result) => {

        if(result.data.factures.length == 0) {
           this.facture.numeroFACTURE = `FA${numberFacture}|${mm}|${yyyy}`;

        } else {
          const LastFACTURE = result.data.factures.length - 1;
          const count = result.data.factures[LastFACTURE].numeroFACTURE.slice(
                  result.data.factures[LastFACTURE].numeroFACTURE.indexOf('A') + 1,
                  result.data.factures[LastFACTURE].numeroFACTURE.lastIndexOf('|'),
          );
          this.facture.numeroFACTURE = 'FA' + (parseInt(count) + 1) +'|'+ mm + '|' + yyyy;
        }

    })
    .catch((err) => {
        console.log(err);
    });


    ///get Affaire specfique
    Service.getAffaireById(this.numeroAffaire)
    .then((result) => {

        // info affaire
        this.tva = result.data.affaire.tva;
        this.affaire = result.data.affaire;
        this.facture.numeroAffaire = result.data.affaire.numeroAffaire;
        this.facture.affaireId = result.data.affaire._id;
        this.facture.numeroBC = result.data.affaire.numeroBonCommande;
        this.chargeAffaire = result.data.affaire.apporteurAffaire;



        // info missions
        Service.getMission(result.data.affaire.numeroAffaire)
        .then((result) => {

              result.data.missions.forEach(element => {
                this.missions.push(element);
              });


              // for fetch table
              result.data.missions.forEach(element => {

                if(element.statusEquipement == false) {

                    //calcul porcentage for get old price
                    const porcentage = 100 - parseInt(element.remise);

                    this.facture.missions.push({
                      codeMission : element.codeMission,
                      titre : element.nameMissionSpecifique == null ? element.typeMission : element.nameMissionSpecifique,
                      prixHt: (((element.prix * 10) / (porcentage/100)) / element.quantite),
                      quantite : element.quantite,
                      remise : element.remise,
                      prixHtFinale: element.prix,
                      devis: element.devis,
                      unite: element.unite
                    });

                }

                if(element.statusEquipement == true) {
                      element.equipement.forEach((el, index) => {
                        this.facture.missions.push({
                        codeMission : element.codeMission,
                        titre : el.titre,
                        prixHt : element.prixEquipement[index],
                        quantite : element.quantiteEquipement[index],
                        remise : element.tauxRemiseEquipement[index],
                        prixHtFinale: element.prixFinaleEquipement[index],
                        devis: element.devis,
                        unite: element.uniteEquipement[index]
                      });
                    });
                }


                this.facture.prixTotalHT = this.facture.prixTotalHT + element.prix;

              });

              this.facture.prixTotalTTC = this.facture.prixTotalHT + ((this.facture.prixTotalHT * this.tva) / 100);
              this.facture.tva = this.tva;


              Service.selectClient(result.data.missions[0].clientId)
              .then((result) => {

                // info client
                this.client = result.data.client;
                this.facture.refClient = result.data.client.refClient
                this.facture.raisonSocial = result.data.client.raisonSocial;
                this.facture.adresse = result.data.client.adresse + ", " + result.data.client.codePostal +"  "+ result.data.client.ville +"  "+ result.data.client.pays;
                this.facture.telephone = result.data.client.telephone;
                this.facture.adresseEnvoi = result.data.client.adresse + ", " + result.data.client.codePostal +"  "+ result.data.client.ville +"  "+ result.data.client.pays;
                this.facture.numeroICE = result.data.client.ice;

              })
              .catch((error) => {
                console.log(error.msg);
              });

        })
        .catch((error) => {
             console.log(error.msg)
        })
    })
    .catch((error) => {
        console.log(error.msg)
    })
}

}
</script>

<style scoped>


.creationClient {
  width: 100%;
  height: 100%;
  margin: 0px;
  padding: 5px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.creationClient h3:nth-child(1) {
  width: 100%;

  color: white;
  background: linear-gradient(346deg, rgba(207,31,33,1) 0%, rgba(24,86,161,1) 100%);    text-align: center;
  text-align: center;
  padding: 15px;

}
.creationClient .titre-client {
    width: fit-content;
    height: fit-content;
    padding: 5px;
    margin: 10px 0;
    color: rgb(41, 41, 41);
    text-align: center;
    width: 100%;
    background-color: #dadada;

}
.creationClient .titre-interlocuteur {
  width: fit-content;
    height: fit-content;
    padding: 5px;
    margin: 10px 0;
    color: rgb(41, 41, 41);
    text-align: center;
    width: 100%;
    background-color: #dadada;


}
.succes {
  background-color: #69cd5b;
  color: white;
  padding: 10px;
  width: 100%;
  height: fit-content;
}

.echec {
  background-color: RED;
  color: white;
  padding: 10px;
  width: 100%;
  height: fit-content;
}

.formCreation {
  padding:20px 0;
  width: 100%;
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  flex-wrap: wrap;

}

.formCreation div {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 48%;
}
.formCreation div label {
  margin-bottom: 5px;
  font-size: 14px;
  font-weight: 700;
  color :#494949;
}
.formCreation div input {
  height: 40px;
  margin-bottom: 5px;
  border: 1px solid #a0a0a0;
  padding:5px;
}.formCreation div input:focus-within {
  outline: 1px solid #cf1f21 ;
  border:0;

}

.formCreation div input:focus-within {
  outline: 1px solid #cf1f21 ;
  border:0;

}


.formCreation div select {
  height: 40px;
}
hr {
  width: 100%;
  background-color: black;
  height: 10px;
}
.enregitreAffaire {
  background-color: green;
  color: white;
  cursor: pointer;
}
.file {
  background-color: white;
  color: black;
  cursor: pointer;
}
.file button{
  background-color: white;
  color: black;
  cursor: pointer;
}
 
.enregistre {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.enregistre button:nth-child(1) {
  margin-top: 30px;
  background-color: rgb(0, 103, 221);
  width: 200px;
  border:0;
  padding:15px;
  color:white;
  cursor: pointer;
  border-radius: 20px;
}

.enregistre button:nth-child(2) {
  margin-top: 30px;
  background-color: rgb(2, 168, 24);
  width: 200px;
  border:0;
  padding:15px;
  color:white;
  cursor: pointer;
  border-radius: 20px;
}

.enregistre {
width: 100%;
}


table  {
  width: 100%;
  border-collapse: collapse;
  margin:10px 0;
  font-weight: 500;
}
table tr  {
  width: 100%;
}
table tr th {
    background-color: #01a1d1;
    color: white;
    padding: 10px 5px;
    border-right: 1px solid white;
}

table input:focus-within{
  outline:0;
  border:1px solid red;
}

table tr td {
    background-color: rgb(255, 255, 255);
    color: black;
    border-bottom: 1px solid rgb(197, 197, 197);
    padding: 5px;
}
table tr td {
  cursor: pointer;
}

table tr td a {
  cursor: pointer;
  margin-left: 10px;
  margin-right: 10px;
}


.synthese tr td {
    width: 50%;
}

.synthese tr td {
    height: 50px;
}

.synthese tr td input {
    width: 100%;
    padding:10px;
}

#app > div > div > div.menu-content > div.content > div > div > h3:nth-child(2) {
  color: white;
}

#app > div > div > div.menu-content > div.content > div > div > h3:nth-child(4) {
  color: white;
}

#app > div > div > div.menu-content > div.content > div > div > h3:nth-child(6) {
  color: white;
}

#app > div > div > div.menu-content > div.content > div > div > h3:nth-child(9) {
  color: white;
}

#inspecteurs > tr:nth-child(3) > td:nth-child(2) > select {
  height: 40px;
  margin-bottom: 5px;
  border: 1px solid #a0a0a0;
  padding:5px;
}

.ajouter {
  background-color: green;
  color: white;
  height: 50px;
  padding: 10px;
  border: 0px;
  margin: 5px;
  width: 100px;
  cursor: pointer;
  border-radius: 20px;
}

.supprimer {
  background-color: red;
  color: white;
  height: 50px;
  padding: 10px;
  border: 0px;
  margin: 5px;
  width: 100px;
  cursor: pointer;
  border-radius: 20px;
}

.input-titre {
  width: 100%;
}

#inspecteurs tr th:nth-child(1){
  width: 10px;
}

#inspecteurs tr th:nth-child(2) {
  width: 1000px;
}

#inspecteurs tr th:nth-child(3) {
  width: 40px;
}

#inspecteurs tr th:nth-child(4) {
  width: 40px;
}


#inspecteurs tr td:nth-child(2) ul li input{
  width: 800px;
  height: 50px;
}

#inspecteurs tr td:nth-child(3) ul li input{
  width: 50px;
  height: 50px;
}

#inspecteurs tr td:nth-child(4) ul li input{
  width: 50px;
  height: 50px;
}

#inspecteurs tr td:nth-child(5) ul li input{
  width: 50px;
  height: 50px;
}

#inspecteurs tr td:nth-child(6) ul li input{
  width: 150px;
  height: 50px;
}

#inspecteurs tr td:nth-child(7) ul li input{
  width: 50px;
  height: 50px;
}

#inspecteurs tr td:nth-child(8) ul li input{
  width: 50px;
  height: 50px;
}


</style>